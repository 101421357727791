import React, { Dispatch } from 'react';
import style from './writeToUsForm.module.scss';
import { Input } from "../Input/Input";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Checkbox } from "@kursk/components/ui/Checkbox/Checkbox";
import { useDispatch } from "react-redux";
import { notificationPush } from "@common/redux/slices/notification.slice";
import { useDispatchThunk } from "@common/redux/dispatchThunk";
import { writeToUsFormSlice, writeToUsThunk } from "@common/redux/slices/writeToUsForm.slice";
import { Textarea } from "@kursk/components/ui/Textarea/Textarea";
import { useSelectSlice } from "@common/redux/selectors/useSelectSlice";
import classNames from "classnames";
import { themeValue } from "@kursk/themes";
import { regionContent } from "@kursk/content/regionContent";

interface IProps {
  inModal?: boolean;
  closeModal?: Dispatch<React.SetStateAction<boolean>> | null;
}

export const WriteToUsForm = ({ inModal = false, closeModal = null }: IProps) => {

  const { isSending } = useSelectSlice(writeToUsFormSlice);
  const dispatch = useDispatch();
  const dispatchThunk = useDispatchThunk();
  const defaultValues = {
    message: '',
    email: '',
    agree: false,
  }

  const { register, handleSubmit, formState, getValues, reset } = useForm({
    mode: 'onChange',
    resolver: yupResolver<any>(yup.object({
      message: yup.string().required('Поле является обязательным').trim(),
      email: yup.string().required('Введите адрес электронной почты')
        .email('Адрес электронной почты введен некорректно'),
      agree: yup.boolean().test({
        test: agree => !!agree,
        message: 'Необходимо подтведить согласие с условиями пользовательского соглашения'
      }),
    })),
    defaultValues: defaultValues
  });

  const onSubmit = async () => {
    const res = await dispatchThunk(writeToUsThunk({ email: getValues().email, message: getValues().message }));
    if (!res.error) {
      dispatch(notificationPush({ content: 'Сообщение отправлено!', type: 'success' }));
      reset(defaultValues);
      if (inModal) {
        closeModal(false);
      }
    }
  }
  return (
    <div className={classNames(inModal ? style.wrapperInModal : style.wrapper)}>
      <div className={style.form}>
        {themeValue({
          bel: <div className={inModal ? style.titleModal : style.title}>
            {inModal ? 'напишите нам' : 'не нашли ответ на вопрос?'}
          </div>,
          default: <div className={style.title}>
            {inModal ? 'напишите нам' : 'не нашли ответ на вопрос?'}
          </div>
        })}
        <div className={inModal ? style.subTitleModal : style.subTitle}>
          {inModal ? 'Принимаем вопросы, жалобы, уточнения и предложения по улучшению'
            : 'Спросите напрямую - ответим лично'}
        </div>
        <div className={style.wrapperInner}>
          <label className={style.textLabel}>
            {inModal ? 'Сообщение' : 'Ваш вопрос'}
          </label>
          <div className={style.inputRow}>
            <Textarea
              {...register('message')}
              errors={formState.errors}
              rows={10}
              className={style.input}
              areaClassName={style.area}/>
          </div>
          <label className={classNames(style.emailLabel, inModal && style.emailLabel_modal)}>Email для
            ответа</label>
          <div className={style.inputRow}>
            <Input {...register('email')} errors={formState.errors}/>
          </div>
          <div className={style.checkboxWrapper}>
            <Checkbox {...register('agree')} errors={formState.errors}>
              <span className={style.agreementText}>Я подтверждаю свое согласие с {' '}
                <a className={'withLine'} target="_blank" href={regionContent.agreementLink} rel="noreferrer">
                  условиями пользовательского соглашения
                </a>
                {' '}
                и
                {' '}
                <a className={'withLine'} target='_blank' href={regionContent.privacyPolicyLink} rel="noreferrer">
                  политикой конфиденциальности
                </a>.
              </span>
            </Checkbox>
          </div>
          <button className={style.button} onClick={handleSubmit(onSubmit)} disabled={isSending}>Отправить</button>
        </div>
      </div>
      {!inModal && (<div className={style.logo}>
        <img src="/images/big-logo-write-us-form.svg" />
      </div>)}
    </div>
  );
};
