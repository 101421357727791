import React, { ReactElement, useState } from 'react';
import Link from 'next/link';
import LinesEllipsis from 'react-lines-ellipsis/lib/loose';
import { ISrcSet, Picture } from '@kursk/components/ui/Picture/Picture';
import styles from './EntityCard.module.scss';
import { IHotel, IPlace, IRestaurant } from "@common/redux/types/places";
import { IArticle } from '@common/redux/types/articles';
import { EntityType } from "@common/redux/types/common";
import { getPriceTitle } from '@kursk/utils/getPriceTitle';

interface IEntityCardProps {
  entity: IPlace | IHotel | IRestaurant | IArticle;
  type?: 'small' | 'medium' | 'large';
  entityType: EntityType;
}

export function EntityCard({ entity, entityType, type = 'small' }: IEntityCardProps): ReactElement {
  const [isHover, setIsHover] = useState(false);

  const imgSrcSet: ISrcSet[] = [
    {
      media: '(min-width: 0px)',
      width: type === 'small' ? 270 : type === 'medium' ? 580 : 1200,
      height: 241,
      cropMode: type === 'small' ? 'noCrop' : 'fromTop',
    }
  ];

  return (
    <Link href={`/${entityType}/${entity._id}/${entity.sysName}`}>
      <a>
        <div
          className={styles.wrapper}
          onMouseOver={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          <div className={styles.badge}>{entity.category?.name}</div>
          <Picture className={styles.image} srcSet={imgSrcSet} image={entity.image}/>
          <div className={styles.info}>
            <LinesEllipsis
              text={entity.name}
              maxLine={isHover ? 4 : 3}
              ellipsis='...'
              trimRight
              basedOn='letters'
              className={styles.title}
            />
            {(entityType === 'places' && (entity.ticketsInfo.available > 0 || entity.ticketsInfo.unlimited)) && (
              <p className={styles.price}>
                {
                  getPriceTitle(entity.ticketsInfo.minPrice, entity.ticketsInfo.maxPrice)
                }
              </p>
            )}
          </div>
        </div>
      </a>
    </Link>
  );
}
