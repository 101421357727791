import { IEventPick, IPlacePick } from "@common/redux/types/picks";

enum size {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

const gridMap = {
  1: [size.large],
  2: [size.medium, size.medium],
  3: [size.medium, size.small, size.small],
  4: [size.small, size.small, size.small, size.small],
  5: [
    size.medium, size.small, size.small,
    size.medium, size.medium
  ],
  6: [
    size.medium, size.small, size.small,
    size.small, size.small, size.medium
  ],
  7: [
    size.medium, size.small, size.small,
    size.small, size.small, size.small, size.small
  ],
  8: [
    size.small, size.small, size.small, size.small,
    size.small, size.small, size.small, size.small
  ],
  9: [
    size.medium, size.small, size.small,
    size.small, size.small, size.medium,
    size.small, size.medium, size.small
  ],
  10: [
    size.medium, size.small, size.small,
    size.small, size.small, size.medium,
    size.small, size.small, size.small, size.small
  ],
};

export const generateGridScheme = (cards: IEventPick[] | IPlacePick[]): Array<size.small | size.medium | size.large> => {

  if (!cards.length) {
    return [];
  }

  const gridTemplate = [];

  const sliceIntoChunks = (cards: IEventPick[] | IPlacePick[], chunkSize = 10) => {
    const res = [];
    for (let i = 0; i < cards.length; i += chunkSize) {
      const chunk = cards.slice(i, i + chunkSize);
      res.push(chunk);
    }
    return res;
  };

  sliceIntoChunks(cards).forEach((cardArr: IEventPick[] | IPlacePick[]) => {
    gridTemplate.push(...gridMap[cardArr.length]);
  });

  return gridTemplate;
}
