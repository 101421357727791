import React, { ReactElement, useState } from "react";
import style from "./Footer.default.module.scss";
import Link from "next/link";
import { regionContent } from "@kursk/content/regionContent";
import { WriteToUsModal } from "@kursk/components/Layout/WriteToUsModal/WriteToUsModal";
import { DzenIcon, OkIcon, ProCultureFooterLogo, TelegramIcon, VkIcon, YoutubeIcon } from "@kursk/components/ui/icons";
import { IMenuItem } from "@kursk/components/Layout/Layout";

export interface IProps {
  links: IMenuItem[];
  useLogo?: string;
}

export function FooterDefault({ links, useLogo }: IProps): ReactElement {
  const [isOpenWriteToUsModal, setIsOpenWriteToUsModal] = useState(false);

  const showWriteToUsModal = () => {
    setIsOpenWriteToUsModal(true);
  }

  const renderTitleOrLogo = () => useLogo ? (
    <Link href={'/'}>
      <a className={style.footer__logo_wrap}>
        <img className={style.footer__logo} src={useLogo} alt="footer logo" />
      </a>
    </Link>
  ) : (
    <div className={style.footer__titleText}>{regionContent.footer.title}</div>
  );

  const socialLinks = [
    regionContent.socials.ok && { link: regionContent.socials.ok, icon: <OkIcon/> },
    regionContent.socials.vk && { link: regionContent.socials.vk, icon: <VkIcon/> },
    regionContent.socials.yaDzen && { link: regionContent.socials.yaDzen, icon: <DzenIcon/> },
    regionContent.socials.telegram && { link: regionContent.socials.telegram, icon: <TelegramIcon/> },
    regionContent.socials.youtube && { link: regionContent.socials.youtube, icon: <YoutubeIcon/> },
  ].filter(item => Boolean(item));

  const policyLinks = [
    regionContent.agreementLink && { link: regionContent.agreementLink, title: 'Публичная оферта' },
    regionContent.privacyPolicyLink && { link: regionContent.privacyPolicyLink, title: 'Политика конфиденциальности' },
    regionContent.refundPolicyLink && { link: regionContent.refundPolicyLink, title: 'Правила возврата' },
  ].filter(item => Boolean(item));

  return (
    <>
      <WriteToUsModal
        isOpen={isOpenWriteToUsModal}
        setIsOpen={setIsOpenWriteToUsModal}
      />
      <div className={style.footer__wrapper}>
        <div className={style.footer__row}>
          <div className={style.footer__section}>
            {renderTitleOrLogo()}
            {socialLinks.length > 0 &&
              <div className={style.footer__socials}>
                {socialLinks.map(({ link, icon }) => (
                  <a key={link} href={link} rel="noreferrer" target="_blank">
                    <div className={style.footer__socialIcon}>
                      {icon}
                    </div>
                  </a>
                ))}
              </div>
            }
          </div>
          <div className={style.footer__section}>
            {links.map((item) =>
              <div key={item.to} className={style.footer__menuLink}>
                {item.target ?
                  (<a href={item.to} target={item.target}>{item.title}</a>) :
                  (<Link href={item.to}>{item.title}</Link>)
                }
              </div>
            )}
          </div>
          {policyLinks.length > 0 &&
            <div className={style.footer__section}>
              {policyLinks.map((item) =>
                <div key={item.link} className={style.footer__menuLink}>
                  <Link href={item.link}><a target='_blank'>{item.title}</a></Link>
                </div>
              )}
            </div>
          }
          <div className={style.footer__section}>
            <div className={style.footer__info}>
              {regionContent.phone &&
                <>
                  <div className={style.footer__title}>Техническая поддержка</div>
                  <div className={style.footer__phone}>
                    <a href={`tel:${regionContent.phone}`}>{regionContent.phone}</a>
                  </div>
                  <div className={style.footer__callTime}>
                    {regionContent.callTime || 'В будние дни с 9:00 до 18:00'}
                  </div>
                </>
              }
              <div className={style.footer__mail}>
                <a href={`mailto:${regionContent.mail}`}>{regionContent.mail}</a>
              </div>
              <div className={style.footer__writeUs}>
                <a onClick={showWriteToUsModal}>Напишите нам</a>
              </div>
              <div className={style.footer__faq}>
                <Link href='/faq'>Часто задаваемые вопросы</Link>
              </div>
              {!regionContent.footer.hidePushkin && (
                <div className={style.footer__pushkincard}>
                  <a target='_blank' href='/pushkincard' rel="noreferrer">Пушкинская карта</a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={style.infoWrapper}>
        <div className={style.info}>
          <div className={style.info__about}>
            <div>
            ООО «Медиа сервис» ИНН 3123287655, КПП 312301001, ОГРН 1113123014773
            </div>
            <div>
            308033, г. Белгород, ул. Королева, 2А, офис 313
            </div>
            <div className={style.info__contacts}>
              <a className={style.info__link} href={`tel:88001006002`}>8 800 100-60-02</a>,{' '}
              <a className={style.info__link} href={`mailto:info@mediaservicellc.ru`}>info@mediaservicellc.ru</a>
            </div>
          </div>
          <div className={style.copyright}>
            <ProCultureFooterLogo className={style.copyright__svg}/>
            <div className={style.copyright__text}>
              {regionContent.footer.systemName}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
