import { IndexPage } from "../components/pages/Home/IndexPage";
import { reduxWrapper } from "@common/redux/store";
import {
  loadBestEventsThunk,
  loadBestHotelsThunk,
  loadBestOverviewsThunk,
  loadBestPlacesThunk,
  loadBestRestaurantsThunk,
  loadBestRoutesThunk,
  loadBestThunk,
  loadBestTicketsBundlesThunk,
  loadMainBannersThunk,
} from "@common/redux/slices/picks";
import { loadWeatherThunk } from "@common/redux/slices/widgetWeather.slice"
import { regionContent } from "@kursk/content/regionContent";


export default function page() {
  return <IndexPage/>;
}

export const getServerSideProps = reduxWrapper.getServerSideProps(async ({ store }) => {
  const isWeatherWidget = regionContent.widgetWeather?.city;

  await Promise.all([
    store.dispatch(loadBestEventsThunk({ complement: false, realm: 'culture' })),
    store.dispatch(loadMainBannersThunk({ complement: false, realm: 'culture' })),
    store.dispatch(loadBestOverviewsThunk({ complement: false, realm: 'culture' })),
    store.dispatch(loadBestRoutesThunk({ complement: false, realm: 'culture' })),
    store.dispatch(loadBestPlacesThunk({ complement: false, realm: 'culture' })),
    store.dispatch(loadBestRestaurantsThunk({ complement: false, realm: 'culture' })),
    store.dispatch(loadBestHotelsThunk({ complement: false, realm: 'culture' })),
    store.dispatch(loadBestTicketsBundlesThunk({ complement: false, realm: 'culture' })),
    store.dispatch(loadBestThunk({ complement: false, realm: 'culture' })),
    isWeatherWidget && store.dispatch(loadWeatherThunk({ city: regionContent.widgetWeather.city })),
  ]);
  return { props: {} };
});
