import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { api } from '../../urls';
import { isServer } from "../../../../utils/server/isServer";
import { isEmpty as _isEmpty } from 'lodash';
import { IUser } from "../types/common";
import { setCookies, getCookie, removeCookies } from 'cookies-next';
import { IImage } from "../types/images";
import { SignUpConfirmPhone, SignUpEmailFields } from "../../../../regions/common/redux/types/auth";

export const AUTH_TOKEN_NAME = 'culture_auth_token';

export interface IState {
  user?: IUser;
  isAuth: boolean | null;
  token: string | null;
  isUpdatingUserInfo: boolean;
  signUpEmailResponse?: {
    success?: boolean
  },
  signUpPhoneResponse?: {
    success?: boolean;
    id?: string;
    error?: string;
    errorName?: 'ServerError' | 'NotFoundError' | 'DuplicatedPhoneRegisterError';
    userMessage?: string;
    confirmPhoneError?: {
      error?: string;
      errorName?: 'ServerError' | 'NotFoundError';
      userMessage?: string;
    }
  },
  signInResponse?: {
    success?: boolean;
    id?: string;
    error?: string;
    errorName?: 'ServerError' | 'NotFoundError';
    userMessage?: string;
    confirmPhoneError?: {
      error?: string;
      errorName?: 'ServerError' | 'NotFoundError';
      userMessage?: string;
    }
  }
}

const setTokenToLocalStorage = (value: string): void => {
  if (isServer()) {
    return;
  }
  if (value) {
    setCookies(AUTH_TOKEN_NAME, value);
  } else {
    removeCookies(AUTH_TOKEN_NAME);
  }
};

export const getTokenFromLocalStorage = (): null |string => {
  if (isServer()) return null;
  return getCookie(AUTH_TOKEN_NAME) as string;
};

export const clearSignInResponseErrors = createAsyncThunk<any, any>('auth/clearSignInResponseErrors',
  async (_, { getState }) => {
    const state = getState()['auth'] as IState;
    return {
      signInResponse: {
        success: state?.signInResponse?.success || false,
        id: state?.signInResponse?.id || undefined,
      }
    }
  }
);

export const loadAuthInfo = createAsyncThunk<any, any>('auth/get',
  async (params) => {
    try {
      if (params.token) {
        const res = await axios.get(api.me.get(), { headers: { Authorization: `Bearer ${params.token}` } });
        return {
          user: res.data.user,
        };
      }
      return {};
    } catch {
      removeCookies(AUTH_TOKEN_NAME);
      return {};
    }
  });

export const changeEmail = createAsyncThunk<any, { email: string, token: string }>('auth/changeEmail',
  async({ email,token }, { rejectWithValue }) => {
    try {
      const data = { email }
      await axios.put(api.auth.changeEmail(), data, { headers: { Authorization: `Bearer ${token}` } })
    } catch (error) {
      return rejectWithValue(error);
    }
  });

export const changePassword = createAsyncThunk<any, { oldPassword: string, newPassword: string, token: string }>
('auth/changePassword',
  async ({ oldPassword, newPassword, token }, { rejectWithValue }) => {
    try {
      const data = { oldPassword, newPassword }
      const res = await axios.put(api.auth.changePassword(), data, { headers: { Authorization: `Bearer ${token}` } })
      return { token: res.data.token }
    } catch (error) {
      return rejectWithValue(error);
    }
  });

export const updateUserInfo = createAsyncThunk<any, any>('auth/updateUserInfo',
  async (params: {
    avatar?: IImage,
    name: string,
    gender: string,
    token: string
  }, { rejectWithValue }) => {
    try {
      const data = params?.avatar ? {
        avatar: params.avatar,
        name: params.name,
        gender: params.gender
      } : {
        name: params.name,
        gender: params.gender
      };
      const res = await axios.put(api.me.update(), data, {
        headers: { Authorization: `Bearer ${params.token}` }
      });
      return {
        user: res.data.user,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  });

export const signIn = createAsyncThunk('auth/signIn',
  async (data: {
    email?: string;
    password?: string;
    token?: string | string[];
  }, { rejectWithValue } ) => {
    if (!data.token) {
      try {
        const res = await axios.put(api.auth.mobile(), data);
        return res.data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
    if (data.token) {
      return data;
    }
  }
);

export const signInPhone = createAsyncThunk<any, { phone: string, gRecaptchaResponse: string }>('auth/signInPhone',
  async (data, { rejectWithValue, dispatch } ) => {
    try {
      const res = await axios.put(api.auth.authPhone(), data);
      if (res.data.token) {
        setTokenToLocalStorage(res.data.token);
        dispatch(loadAuthInfo({}));
        return {
          token: res.data.token
        }
      }
      return {
        signInResponse: { ...res.data }
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const confirmSignInPhone = createAsyncThunk<any, SignUpConfirmPhone>('auth/confirmSignInPhone',
  async (data, { rejectWithValue, dispatch } ) => {
    try {
      const res = await axios.put(api.auth.confirmAuthPhone(), data);
      const token = res.data.token;
      setTokenToLocalStorage(token);
      dispatch(loadToken({}));
      return { token };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const confirmRegisterPhone = createAsyncThunk<any, SignUpConfirmPhone>('auth/confirmRegisterPhone',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.put(api.auth.confirmRegisterPhone(), data);
      if (res.data.token) {
        setTokenToLocalStorage(res.data.token);
        dispatch(loadToken({}));
        return {
          token: res.data.token,
          isAuth: true,
        }
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  });

export const restorePassword = createAsyncThunk('auth/restorePassword',
  async (data: { email: string; }, { rejectWithValue } ) => {
    try {
      const res: any = await axios.put(api.auth.restorePassword(), data);

      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  });

export const loadAvatar = createAsyncThunk('auth/loadAvatar',
  async (data: { formData: FormData, token: string }, { rejectWithValue } ) => {
    try {
      const res: any = await axios.post(api.files.upload(), data.formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${data.token}`
        }
      });

      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  });

export const restorePasswordFromLink = createAsyncThunk('auth/restorePasswordFromLink',
  async (data: {
    password: string;
    token: string;
  }, { rejectWithValue, dispatch } ) => {
    try {
      const res: any = await axios.put(api.auth.confirmRestore(), data);
      setTokenToLocalStorage(res.data.token);
      dispatch(loadToken({ token: res.data.token }));
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  });

export const signUp = createAsyncThunk<any, SignUpEmailFields>('auth/signUp',
  async (data, { rejectWithValue } ) => {
    try {
      const res = await axios.put(api.auth.register(), data);

      return {
        signUpEmailResponse: { ...res.data }
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const signUpPhone = createAsyncThunk<any, {
  phone: string,
  name: string,
  gender: string,
  gRecaptchaResponse: string
}>('auth/signUpPhone',
  async (data, { rejectWithValue }) => {
    try {
      const res = await axios.put(api.auth.registerPhone(), data);

      return {
        signUpPhoneResponse: { ...res.data }
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  });

export const loadToken = createAsyncThunk<any, any>(
  'auth/loadToken',
  async ({ token = null } = {}, { getState, dispatch }) => {
    const state = getState()['auth'] as IState;
    const tokenFromCookie = getTokenFromLocalStorage();
    const authToken = token || state.token || tokenFromCookie;
    const isAuth = state.isAuth || Boolean(tokenFromCookie);
    if (_isEmpty(state.user)) {
      const { user } = (await dispatch(loadAuthInfo({ token: authToken }))).payload;
      if (!user) {
        return {
          token: null,
          isAuth: false,
        }
      }
      return {
        token: authToken,
        isAuth: true,
        user: user,
      }
    }
    return {
      token: authToken,
      isAuth: isAuth,
    }
  });

export const logout = createAsyncThunk<any>('auth/logout', () => {
  setTokenToLocalStorage('');
  return {
    token: '',
    isAuth: false,
    user: undefined
  }
});

export const authSlice = createSlice<IState, any>({
  name: 'auth',
  initialState: {
    isAuth: null,
    token: null,
    isUpdatingUserInfo: false
  },
  reducers: {
  },
  extraReducers: builder => {
    builder.addCase(loadToken.fulfilled, (state, { payload }) => Object.assign(state, payload));
    builder.addCase(logout.fulfilled, (state, { payload }) => Object.assign(state, payload));
    builder.addCase(signUp.fulfilled, (state, { payload }) => Object.assign(state, payload));
    builder.addCase(clearSignInResponseErrors.fulfilled, (state, { payload }) => Object.assign(state, payload));
    builder.addCase(confirmSignInPhone.fulfilled, (state, { payload }) => Object.assign(state, payload));
    builder.addCase(confirmSignInPhone.rejected, (state, { payload }: any) => {
      state.signInResponse.confirmPhoneError = payload.response.data;
    });
    builder.addCase(confirmRegisterPhone.fulfilled, (state, { payload }) => Object.assign(state, payload));
    builder.addCase(confirmRegisterPhone.rejected, (state, { payload }: any) => {
      state.signUpPhoneResponse.confirmPhoneError = payload.response.data;
    });
    builder.addCase(signInPhone.fulfilled, (state, { payload }) => Object.assign(state, payload));
    builder.addCase(signInPhone.rejected, (state, { payload }: any) => {
      state.signInResponse = payload.response.data;
    });
    builder.addCase(signUpPhone.rejected, (state, { payload }: any) => {
      state.signUpPhoneResponse = payload.response.data;
    });
    builder.addCase(signUpPhone.fulfilled, (state, { payload }) => Object.assign(state, payload));
    builder.addCase(loadAvatar.fulfilled, (state, { payload }) => Object.assign(state, payload));
    builder.addCase(updateUserInfo.pending, (state, { payload }) => {
      state.isUpdatingUserInfo = true;
      Object.assign(state, payload);
    });
    builder.addCase(changeEmail.pending, (state) => {
      state.isUpdatingUserInfo = true;
    });
    builder.addCase(changeEmail.fulfilled, (state) => {
      state.isUpdatingUserInfo = false;
    });
    builder.addCase(changePassword.fulfilled, (state,{ payload }) => {
      state.token = payload.token;
      setTokenToLocalStorage(payload.token);
    });
    builder.addCase(updateUserInfo.fulfilled, (state, { payload }) => {
      state.isUpdatingUserInfo = false;
      Object.assign(state, payload);
    });
    builder.addCase(signIn.fulfilled, (state, { payload }) => {
      if (payload.token === undefined) {
        state.isAuth = false;
        state.token = null
        return;
      }
      state.isAuth = true;
      state.token = payload.token
      setTokenToLocalStorage(payload.token);
    });
  }
});
