import React, { ReactElement } from 'react';
import styles from "@kursk/components/Layout/AuthModal/SignUpForm/SignUpForm.module.scss";
import { Input } from "@kursk/components/ui/Input/Input";
import { useForm } from "react-hook-form";
import _ from "lodash";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { BaseSignUpForm } from "@kursk/components/Layout/AuthModal/SignUpForm/Blocks/BaseSignUpForm";
import { SignUpEmailFields } from "@common/redux/types/auth";

interface Props {
  goToLoginForm: () => void;
  onSubmit: (values: SignUpEmailFields) => Promise<any>;
  registrationError?: string;
}

export const SignUpEmail = ({
  goToLoginForm,
  onSubmit,
  registrationError }: Props): ReactElement => {

  const { handleSubmit, register, formState: {
    errors, isDirty, isValid }, getValues, control
  } = useForm({
    mode: "onChange",
    resolver: yupResolver<any>(Yup.object({
      email: Yup.string().email('Указан некорректный адрес электронной почты').required('Необходимо ввести почту'),
      password: Yup.string().required('Необходимо ввести пароль'),
      repeatPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Пароли должны совпадать'),
      name: Yup.string().required('Это поле необходимо заполнить'),
      gender: Yup.string().required(),
      agree: Yup.boolean().test({
        test: agree => !!agree,
        message: 'Необходимо подтвердить согласие с условиями пользовательского соглашения'
      }),
    })),
    defaultValues: {
      email: '',
      password: '',
      name: '',
      agree: false,
      gender: 'none',
    }
  });


  const onFormSubmit = async () => {
    const values = _.pick(getValues(),['email', 'password', 'name' ,'gender']);
    await onSubmit(values);
  };
  return (
    <>
      <BaseSignUpForm
        onSubmit={onFormSubmit}
        handleSubmit={handleSubmit}
        register={register}
        genderControl={control}
        errors={errors}
        disabledRegisterBtn={!isDirty || !isValid}
        goToLoginForm={goToLoginForm}
        isPhoneSignUpForm={false}
        reloadCaptcha={false}
        setReloadCaptcha={_.noop}
      >
        <>
          <label className={styles.label}>
                Email
          </label>
          <div className={styles.input_wrapper}>
            <Input
              {...register("email")}
              errors={errors}
            />
            {registrationError &&
                  <div className={styles.text_error}>Указанный адрес электронной почты уже зарегистрирован</div>}
          </div>
          <label className={styles.label}>
                Пароль
          </label>
          <div className={styles.input_wrapper}>
            <Input
              type="password"
              {...register("password")}
              errors={errors}
            />
          </div>
          <label className={styles.label}>
                Подтверждение пароля
          </label>
          <div className={styles.input_wrapper}>
            <Input
              type="password"
              {...register("repeatPassword")}
              errors={errors}
            />
          </div>
        </>
      </BaseSignUpForm>
    </>
  )
};
