import React, { Dispatch } from "react";
import Link from "next/link";
import { IHint } from "@kursk/components/pages/Search/Search";
import styles from "./SuggestionList.module.scss";

interface SuggestionListProps {
  items: IHint[],
  searchQuery: string | string[],
  className: string,
  totalItemClassname: string,
  setIsOpen?: Dispatch<React.SetStateAction<boolean>>;
}

interface ItemProps {
  item: IHint,
}

export const SuggestionList = ({
  items,
  searchQuery,
  className,
  totalItemClassname,
  setIsOpen,
}: SuggestionListProps): JSX.Element => {

  const ItemCard = ({ item }: ItemProps): JSX.Element => {
    const { _id, sysName, name, type } = item;
    return (
      <Link
        href={`/${type}/${_id}/${sysName}`}>
        {name}
      </Link>
    )
  }

  return (
    <div className={className}>
      {searchQuery.length > 3 && items && items.map(suggestion => (
        <div
          className={styles.suggestionsItem}
          key={suggestion._id}
          onClick={() => setIsOpen(false)}
        >
          <ItemCard item={suggestion}/>
        </div>
      ))}
      <Link href={`/search?nameQuery=${searchQuery}`}>
        <div
          className={totalItemClassname}
          onClick={() => setIsOpen(false)}
        >
          Все результаты по поиску «{searchQuery}»
        </div>
      </Link>
    </div>
  )
}


