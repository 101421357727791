import React, { ReactElement } from 'react';
import { getComponentVersion } from "@kursk/utils/componentsTemplates";
import { Page } from "@kursk/components/seo/seo.types";
import { FooterDefault, IProps } from './Footer.default/Footer.default';
import { FooterBel } from './Footer.bel/Footer.bel';

const componentsHash = {
  default: FooterDefault,
  bel: FooterBel,
}

interface FooterComponentProps extends IProps {
  page?: Page,
}

export function FooterComponent({ page, ...props }: FooterComponentProps): ReactElement {

  const componentVersion = getComponentVersion({ componentType: 'footer', page });
  const Component = componentsHash[componentVersion];

  return <Component {...props} />
}
