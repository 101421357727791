import React, { Dispatch } from 'react';
import classNames from 'classnames';
import styles from './OptionsForm.module.scss';
import { Button } from "@kursk/components/ui/Button/Button";
import { IconButton } from '../IconButton/IconButton';
import { themeValue } from "@kursk/themes";
import { getConfig } from "@root/config/config";
import { regionContent } from "@kursk/content/regionContent";
import { esiaLogin } from "@common/utils/esia";

export interface OptionsFormProps {
  goToLoginForm: Dispatch<React.SetStateAction<string>> | any;
  goToSignupForm: Dispatch<React.SetStateAction<string>> | any;
  goToLostPasswordForm: Dispatch<React.SetStateAction<string>> | any;
  goToLoginPhone: Dispatch<React.SetStateAction<string>> | any;
}

export const OptionsForm = ({ goToLoginForm, goToSignupForm, goToLoginPhone }: OptionsFormProps) => {
  const goToVkLogin = () => {
    // const returnUrl = encodeURIComponent(window.location.href);
    // window.location.replace(`/api/1.0/oauth/login/vk?returnUrl=${returnUrl}`);

    window.location.replace(`/api/1.0/oauth/login/vk`);
  }

  const goToEsiaLogin = () => {
    esiaLogin();
  }

  const config = getConfig();

  return (
    <div>
      <h1 className={styles.header}>ЛИЧНЫЙ КАБИНЕТ</h1>
      <div className={styles.textBlock}>
        <div className={styles.text}>
          В личном кабинете вы сможете просматривать список купленных билетов,
          скачивать pdf-файлы с билетами, а также выводить на экран QR-код билета.
        </div>
      </div>

      <p className={classNames(styles.delimeter, styles.delimeter_enter)}>Войти с помощью</p>

      <IconButton
        onClick={goToLoginForm}
        text='EMAIL'
        icon='email'
        className={classNames(styles.button, themeValue({
          default: '',
          bel: styles.email_bel
        }))}
        withoutGap
      />

      {!!config.auth.phone && config.reCaptchaKey ? (<IconButton
        onClick={goToLoginPhone}
        text='ТЕЛЕФОН'
        icon='phone'
        className={classNames(styles.button, themeValue({
          default: '',
          bel: styles.email_bel
        }))}
        withoutGap
      />) : null}

      {!!config.auth.vk && (<IconButton
        onClick={goToVkLogin}
        text='ВКОНТАКТЕ'
        icon='vk'
        buttonType={'vk'}
        className={classNames(styles.button)}
        withoutGap
      />)}

      {!!config.auth.esia && (<IconButton
        onClick={goToEsiaLogin}
        icon='esia'
        buttonType={'esia'}
        className={classNames(styles.button)}
        withoutGap
      />)}

      <p className={classNames(styles.delimeter, styles.delimeter_or)}>Или</p>

      <Button
        text='ЗАРЕГИСТРИРОВАТЬСЯ'
        onClick={goToSignupForm}
        buttonType='ghostButton'
      />

      <div>
        <p className={classNames(styles.agreement)}>
          Авторизуясь, вы соглашаетесь с {' '}
          <a className={'withLine'} href={regionContent.agreementLink} target='_blank' rel="noreferrer">
            условиями пользовательского соглашения
          </a>
          {' '}
          и
          {' '}
          <a className={'withLine'} href={regionContent.privacyPolicyLink} target='_blank' rel="noreferrer">
            политикой конфиденциальности
          </a>.
        </p>
      </div>
    </div>
  );
};
