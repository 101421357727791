import React, { Dispatch, ReactElement, useEffect, useState } from 'react';
import classNames from 'classnames';
import { View, ViewPanel } from "@kursk/components/Layout/AuthModal/View/View";
import styles from './SignUpForm.module.scss';
import { SignUpEmail } from "@kursk/components/Layout/AuthModal/SignUpForm/Blocks/SignUpEmail";
import { SignUpTel } from "@kursk/components/Layout/AuthModal/SignUpForm/Blocks/SignUpTel";
import { ConfirmView } from "@kursk/components/Layout/AuthModal/ConfirmView/ConfirmView";
import { Input } from "@kursk/components/ui/Input/Input";
import { prettifyPhoneWithCode } from "@common/utils/phones";
import { Button } from "@kursk/components/ui/Button/Button";
import { useDispatchThunk } from "@common/redux/dispatchThunk";
import { authSlice, confirmRegisterPhone, signUp, signUpPhone } from "@common/redux/slices/auth.slice";
import { useSelectSlice } from "@common/redux/selectors/useSelectSlice";
import { notificationPush } from "@common/redux/slices/notification.slice";
import { useDispatch } from "react-redux";
import { SignUpEmailFields, SignUpFormTabs, SignUpPhoneFields } from "@common/redux/types/auth";
import { BackArrowIcon } from "@kursk/components/ui/icons";
import { getConfig } from "@root/config/config";

interface Props {
  goToLoginForm: () => void;
  goBack: () => void;
  setIsOpen: Dispatch<React.SetStateAction<boolean>>;
  type?: SignUpFormTabs;
}

export type SignUpFormPanels = 'signup' | 'send_link' | 'send_code';


export const SignUpForm = ({ goToLoginForm, type, setIsOpen, goBack }: Props): ReactElement => {

  const config = getConfig();
  const dispatch = useDispatch();
  const dispatchThunk = useDispatchThunk();
  const { signUpPhoneResponse } = useSelectSlice(authSlice);
  const [activePanel, setActivePanel] = useState<SignUpFormPanels>('signup');
  const [tabPanel, setTabPanel] = useState<SignUpFormTabs>(type || 'email');
  const [confirmCode, setConfirmCode] = useState<string>('');
  const [registrationError, setRegistrationError] = useState<string>('');
  const [signUpFormValues, setSignUpFormValues] =
    useState<SignUpEmailFields | SignUpPhoneFields | Record<string, any>>({});

  const onSubmitEmail = async (values: SignUpEmailFields): Promise<any> => {
    setSignUpFormValues(values);
    const res = await dispatchThunk(signUp(values));
    if (!res.error) {
      setActivePanel('send_link');
    }
  };

  const onSubmitPhone = async (values: SignUpPhoneFields): Promise<any> => {
    setSignUpFormValues(values);
    const res = await dispatchThunk(signUpPhone(values));
    if (!res.error) {
      setActivePanel('send_code');
    }
  };

  useEffect(() => {
    setRegistrationError('');
  }, [activePanel, tabPanel]);

  useEffect(() => {
    if (signUpPhoneResponse?.errorName === 'ServerError') {
      setRegistrationError('Не удалось осуществить звонок по указанному номеру');
    } else if (signUpPhoneResponse?.errorName === 'DuplicatedPhoneRegisterError') {
      setRegistrationError('Данный номер телефона уже зарегистрирован');
    } else if (signUpPhoneResponse?.confirmPhoneError) {
      setRegistrationError('Неверный код');
    } else {
      setRegistrationError('');
    }
  }, [signUpPhoneResponse]);

  const confirmPhone = async () => {
    const res = await dispatchThunk(confirmRegisterPhone({
      code: confirmCode,
      id: signUpPhoneResponse.id,
    }));
    if (!res.error) {
      dispatch(notificationPush({ content: 'Вы успешно зарегистрировались', type: 'success' }));
      setIsOpen(false);
    }
  };

  return (
    <>
      <div className={styles.backArrow} onClick={goBack}>
        <BackArrowIcon/>
      </div>
      <View activePanel={activePanel}>

        <ViewPanel panelId="signup">
          <>
            <h1 className={classNames(styles.header, styles.header_title)}>Регистрация</h1>

            {!!config.auth.phone && config.reCaptchaKey && (
              <div className={styles.tabSection}>
                <div
                  className={classNames([styles.tabEmail, tabPanel === 'email' && styles.tabActive])}
                  onClick={() => setTabPanel('email')}
                >
                  <span>ПОЧТА</span>
                </div>
                <div
                  className={classNames([styles.tabTel, tabPanel === 'phone' && styles.tabActive])}
                  onClick={() => setTabPanel('phone')}
                >
                  <span>ТЕЛЕФОН</span>
                </div>
              </div>
            )}

            {tabPanel === 'email' && (
              <SignUpEmail
                goToLoginForm={goToLoginForm}
                onSubmit={onSubmitEmail}
                registrationError={registrationError}
              />
            )}
            {tabPanel === 'phone' && (
              <SignUpTel
                goToLoginForm={goToLoginForm}
                onSubmit={onSubmitPhone}
                registrationError={registrationError}
                onChange={() => setRegistrationError('')}
              />
            )}
          </>
        </ViewPanel>

        <ViewPanel panelId="send_link">
          {(signUpFormValues as SignUpEmailFields).email && (
            <ConfirmView
              title='Регистрация'
              description={`На почту ${
                (signUpFormValues as SignUpEmailFields).email
              } отправлено письмо со ссылкой для регистрации.`}
              onSubmit={() => onSubmitEmail((signUpFormValues as SignUpEmailFields))}
              resendText='выслать повторно'
              countText='Повторная отправка возможна через'
            />
          )}
        </ViewPanel>

        <ViewPanel panelId="send_code">
          {(signUpFormValues as SignUpPhoneFields).phone && (
            <ConfirmView
              title='Подтверждение'
              description={`
          На номер ${prettifyPhoneWithCode((signUpFormValues as SignUpPhoneFields).phone)} вам поступит звонок.
          Введите в поле ниже последние 4 цифры номера
          `}
              onSubmit={() => onSubmitPhone((signUpFormValues as SignUpPhoneFields))}
              resendText='Запросить код повторно'
              countText='Повторный запрос кода возможен через'
              footer={<Button
                onClick={confirmPhone}
                type="button"
                text={'ВОЙТИ'}
              />}
            >
              <>
                <label className={styles.label}>
                  Код подтверждения
                </label>
                <div className={styles.input_wrapper}>
                  <Input
                    type="text"
                    name='code'
                    onChange={(e) => {
                      setRegistrationError('');
                      setConfirmCode(e.target.value);
                    }}
                    error={registrationError}/>
                </div>
              </>
            </ConfirmView>
          )}
        </ViewPanel>

      </View>
    </>
  );
};
