import classNames from 'classnames';
import React, { ReactElement } from 'react';
import styles from './Picture.module.scss';
import { IImage } from "@common/redux/types/images";
import { getImageUrl, getImageCropFromTop } from "@common/utils/image";

export interface ISrcSet {
  media: string;
  height: number;
  width: number;
  // Объект image можно задать 2 способами - в основных свойствах или непосредственно в SrcSet-е
  // 2ой вариант нужен если для разных устройств нужно показывать изображения из разных источников,
  // поэтому он имеет более высокий приорите
  image?: IImage;
  // Если не передаем cropMode то будет дефолтное поведение (будет использоваться image.crop если он есть)
  cropMode?: 'noCrop' | 'fromTop';
}

interface IPictureProps {
  className?: string;
  srcSet: ISrcSet[];
  image?: IImage;
}

export function Picture({ className, image, srcSet }: IPictureProps): ReactElement {
  const style: React.CSSProperties = image?.averageColor ? {
    backgroundColor: `#${image.averageColor}`
  } : {};

  // в качестве изображения по-умолчанию используем вариант с самой большой шириной
  // Или - если его нет, показываем оригинал
  let defaultSrc: ISrcSet = { width: null, height: null, media: '' };
  if (srcSet.length > 0) {
    defaultSrc = srcSet.sort((a, b) => a.width - b.width)[0];
  }

  const getImageItem = (image: IImage, srcSet: ISrcSet): IImage => {
    switch (srcSet.cropMode) {
      case "noCrop": {
        return { ...image, crop: null };
      }
      case "fromTop": {
        return { ...image, crop: getImageCropFromTop(image, srcSet.width / srcSet.height) }
      }
      default: {
        return image;
      }
    }
  };

  return (
    <div style={style} className={classNames(className, styles.image)}>
      <picture>
        {
          srcSet.map((item) =>
            <source
              key={item.media}
              media={item.media}
              srcSet={getImageUrl(
                item.image ? getImageItem(item.image, item) : getImageItem(image, item),
                item.width,
                item.height,
              )}
            />
          )}
        <img
          srcSet={getImageUrl(
            getImageItem(image, defaultSrc),
            defaultSrc.width,
            defaultSrc.height,
          )}
          alt={image?.description || ''}
        />
      </picture>
    </div>
  );
}
