import React, { ReactElement, useState } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Input } from "@kursk/components/ui/Input/Input";
import { Button } from "@kursk/components/ui/Button/Button";
import { View, ViewPanel } from "@kursk/components/Layout/AuthModal/View/View";
import styles from "@kursk/components/Layout/AuthModal/LostPasswordForm/LostPasswordForm.module.scss";
import { restorePassword } from "@common/redux/slices/auth.slice";
import { useDispatchThunk } from "@common/redux/dispatchThunk";
import { ConfirmView } from "@kursk/components/Layout/AuthModal/ConfirmView/ConfirmView";
import { BackArrowIcon } from "@kursk/components/ui/icons";

export interface LostPasswordFormProps {
  goToSignupForm?: () => void;
  goBack?: () => void;
}

export const LostPasswordForm = ({ goToSignupForm, goBack }: LostPasswordFormProps): ReactElement => {

  const dispatchThunk = useDispatchThunk();
  const [activePanel, setActivePanel] = useState<'enter_password' | 'send_link'>('enter_password');
  const [email, setEmail] = useState<string>('');
  const [restorePasswordError, setRestorePasswordError] = useState<boolean>(false);

  const { handleSubmit, register, formState: { errors, isDirty, isValid }, getValues } = useForm({
    mode: "onChange",
    resolver: yupResolver<any>(Yup.object({
      email: Yup.string().email('Указан некорректный адрес электронной почты').required('Необходимо ввести почту'),
    })),
    defaultValues: {
      email: '',
    }
  });

  const onSubmit = async () => {
    const res = await dispatchThunk(restorePassword({ email: getValues().email }));
    if (!res.error) {
      if (res.payload.success !== false) {
        setEmail(getValues().email);
        setActivePanel('send_link');
        setRestorePasswordError(false);
      } else {
        setRestorePasswordError(true);
      }
    }
    if (res.error) {
      setRestorePasswordError(true);
    }
  };

  return (
    <>
      <div className={styles.backArrow} onClick={goBack}>
        <BackArrowIcon/>
      </div>
      <View activePanel={activePanel}>
        <ViewPanel panelId="enter_password">
          <form onSubmit={handleSubmit(onSubmit)}>
            <h1 className={styles.header}>ЗАБЫЛИ ПАРОЛЬ?</h1>
            <div className={styles.textBlock}>
              <div className={styles.text}>
               Введите email, указанный при регистрации. Мы отправим вам письмо со ссылкой для восстановления.
              </div>
            </div>
            <label className={styles.label}>
             Email
            </label>
            <div className={styles.input_wrapper}>
              <Input
                {...register("email")}
                errors={errors}
              />
              {restorePasswordError &&
               <div className={styles.text_error}>Не найден пользователь с такой почтой</div>}
            </div>
            <Button
              text='ОТПРАВИТЬ'
              type="submit"
              buttonType='greenPrimary'
              className={styles.marginBottom}
              disabled={!isDirty || !isValid}
            />
            {goToSignupForm &&
             <Button
               type="button"
               buttonType='ghostButton'
               text='ЗАРЕГИСТРИРОВАТЬСЯ'
               onClick={() => goToSignupForm()}
             />}
          </form>
        </ViewPanel>
        <ViewPanel panelId='send_link'>
          <ConfirmView
            title='Восстановление пароля'
            description={`На почту ${email} отправлено письмо со ссылкой для восстановления пароля.`}
            onSubmit={onSubmit}
            resendText='выслать повторно'
            countText='Повторная отправка возможна через'
          />
        </ViewPanel>
      </View>
    </>
  )
};
