import { defaultEntitiesNames } from './entitiesNames';
import { THEME_HASH } from "@kursk/themes";
import { kurskContent } from './kursk/kurskContent';
import { siriusContent } from './sirius/siriusContent';
import { belContent } from "@kursk/content/bel/belContent";
import { primorskyContent } from './primorsky/primorskyContent';
import { vladimirContent } from './vladimir/vladimirContent';
import { sakhalinContent } from './sakhalin/sakhalinContent';
import { pprogContent } from './pprog/pprogContent';
import { sochiContent } from './sochi/sochiContent';
import { lnrContent } from './lnr/lnrContent';
import { muzaContent } from './muza/muzaContent';

const region = process.env.NEXT_PUBLIC_REGION_THEME;

const content: THEME_HASH = {
  kursk: kurskContent,
  sirius: siriusContent,
  bel: belContent,
  primorsky: primorskyContent,
  sakhalin: sakhalinContent,
  vladimir: vladimirContent,
  pprog: pprogContent,
  sochi: sochiContent,
  lnr: lnrContent,
  muza: muzaContent,
};

export const regionContent = { entitiesNames: defaultEntitiesNames, ...content[region] };
