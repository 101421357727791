/* eslint-disable max-len */
import { envVal } from '@root/config/config';
import moment from 'moment';
import { getStreamPlaceOrEvent } from "@kursk/content/contentUtils";
import { capitalize } from 'lodash';

const year = new Date().getFullYear();

const pageWithTag = {
  title: ({ title }) => `${title[0].toUpperCase() + title.slice(1)} в Белгороде | Афиша мероприятий с точными датами на ${year} год - Культурный Регион`,
  description: ({ title }) => `${title[0].toUpperCase() + title.slice(1)} в Белгороде на ${year} год ✅ Афиша интересных мероприятий в Белгородской области. Подробная информация о событиях: ⭐ описания, места, расписания, ☎ телефоны. Культурный регион`,
  titleOg: () => '',
};

const tagPageDefaultSeo = {
  title: ({ tagName }) => `${capitalize(tagName)} в Белгороде ${year}. Культурный Регион`,
  description: ({ tagName }) => `${capitalize(tagName)} в Белгороде. Подробная информация о событиях: расписания, описания, фото, места проведения, цены на билеты. Официальный сайт Культурный Регион`,
  titleOg: () => '',
  h1: ({ tagName }) => tagName
};

export const belContent = {
  timezone: 'Europe/Moscow',
  yandexMapState: {
    center: [50.36, 36],
    zoom: 9,
  },
  locale: { _id: 2 },
  title: 'Культурный регион',
  subTitle: 'Культурный регион. Белгород.',
  mainBanner: {
    hide: true,
    title: 'Белгородская область',
    text: '«Культурный регион» — ваш портал в культурную жизнь области',
    links: [
      { link: '/overviews', text: 'Что посмотреть' },
      { link: '/places', text: 'Что посетить' },
      { link: '/restaurants', text: 'Где поесть' },
    ]
  },
  useBvi: false,
  phone: '8 800 350-75-53',
  mail: 'support@cultreg.ru',
  agreementLink: '/static/public-offer-19-06-2024.pdf',
  socials: {
    ok: 'https://ok.ru/kultbel',
    vk: 'https://vk.com/kultbel',
    yaDzen: 'https://zen.yandex.ru/bel_cultreg',
    telegram: 'https://t.me/belevents',
    tiktok: 'https://www.tiktok.com/@bel_vibe',
  },
  socialTitles: {
    vk: '«Культурный регион»\nво «вконтакте»',
    telegram: '«Культурный регион»\nв Telegram',
  },
  subscribeForm: {
    title: 'Узнавайте новости культуры первыми',
  },
  header: {
    links: [
      {
        title: 'Афиша',
        relativeLink: '/events',
        links: [
          { title: 'Все события', to: '/events' },
          { title: 'Пушкинская карта', to: '/events/tag-pushkinskaya-karta' },
          { title: 'Концерты и шоу', to: '/events/tag-koncerty-i-shou' },
          { title: 'Спектакли', to: '/events/tag-spektakli' },
          { title: 'Выставки', to: '/events/tag-vystavki' },
          { title: 'Мастер-классы', to: '/events/tag-master-klassy' },
          { title: 'Кино', to: '/movies' },
          { title: 'Фестивали', to: '/events/tag-festivali' },
          { title: 'Праздники', to: '/events/tag-prazdniki' }
        ]
      },
      { to: '/places', title: 'Места' },
      { to: '/touristroutes', title: 'Маршруты' },
      { to: '/overviews', title: 'Обзоры' },
      { to: '/districts', target: '_blank', title: 'Районы' },
      { title: 'Спецпроекты', linksSource: 'sprojects' },
      {
        title: 'Live',
        links: [
          { to: '/streams', title: 'Трансляции' },
          { to: '/artefacts', title: 'Дополненная реальность' },
          { to: '/museums', title: 'Виртуальные музеи' },
          { to: '/regionvideos', title: 'Посмотреть регион' },
          { to: '/halls', title: 'Виртуальные концертные залы' },
        ],
      },
      {
        title: 'Ещё',
        links: [
          { to: '/restaurants', title: 'Кафе и рестораны' },
          { to: '/hotels', title: 'Гостиницы' },
        ],
      },
    ],
  },
  footer: {
    title: 'Культурный регион. Белгород.',
    systemName: `© ${year}. Культурный регион. Белгород. Все права защищены.`,
    showProCultureLogo: true,
    links: [
      {
        to: '/events',
        title: 'Афиша'
      },
      {
        to: '/ticketsbundles',
        title: 'Абонементы'
      },
      {
        to: '/places',
        title: 'Места'
      },
      {
        to: '/touristroutes',
        title: 'Маршруты',
      },
      {
        to: '/overviews',
        title: 'Обзоры'
      },
      { to: '/hotels', title: 'Гостиницы' },
      { to: '/restaurants', title: 'Рестораны' },
      { to: '/map', title: 'Интерактивная карта', target: '_blank', },
      { to: '/pushkincard', title: 'Пушкинская карта' },
    ],
  },
  analytics: {
    yandex: envVal({ production: '46740162', staging: '93250407' }, null),
    gtm: envVal({ production: 'GTM-PNX5M5W' }, null),
    pixel: envVal({ production: '16' }, null),
    goals: {
      paidVisit: 'events_visit',
      paidBuy: 'buy_ticket',
      freeVisit: 'events_free',
      freeNoTicketsVisit: 'noactive_free',
      freeBuy: 'freeandzero',
      externalLinkVisit: 'events_visit_redirect',
      externalLinkBuy: 'buy_redirect',
      ticketsAdd: 'entity_ticket_add',
    }
  },
  unisenderNewsPopup: {
    id: envVal({ staging: '85189' }, null),
  },
  componentsConfig: {
    header: 'bel',
    footer: 'bel',
    heroBlock: 'banner',
    socialCardIconVk: 'default',
    socialCardIconTg: 'default',
  },
  seo: {
    pages: {
      index: {
        title: () => 'Культурный регион - Культурно-туристический портал Белгородской области',
        description: () => 'Культурно-туристический портал Белгорода: события, места, маршруты, обзоры, гостиницы и рестораны. Культурный регион',
        titleOg: () => '',
      },
      events: {
        title: () => `Куда сходить в Белгороде ${year}. Афиша ближайших мероприятий Белгорода. Интересные развлекательные события.  Культурный регион`,
        description: () => 'Куда можно пойти в Белгороде ✅. Афиша интересных мероприятий в Белгородской области. Подробная информация о событиях: ⭐ описания, места, расписания, ☎ телефоны. Культурный регион',
        titleOg: () => '',
      },
      'koncerty-i-shou/events': pageWithTag,
      'spektakli/events': pageWithTag,
      'vystavki/events': pageWithTag,
      'master-klassy/events': pageWithTag,
      'festivali/events': pageWithTag,
      'prazdniki/events': pageWithTag,
      places: {
        title: () => `Все интересные места и достопримечательности Белгорода ${year}. Культурный регион`,
        description: () => `Интересные и необычные достопримечательности ${year} — описания, цены на билеты, адреса, режим работы, фотографии, адреса на карте. Культурный регион`,
        titleOg: () => '',
      },
      'dvorcy-kultury-i-kluby/places': {
        title: () => `Дворцы культуры в городе Белгород | Список ДК с адресами`,
        description: () => `Список дворцов культуры в Белгороде с адресами, номерами и фотографиями. Актуальная информация на ${year} год`,
        titleOg: () => '',
      },
      routes: {
        title: () => `Туристические маршруты в Белгороде ${year}. Культурный регион`,
        description: () => `Лучшие туристические маршруты, которые можно исходить пешком или проехать на автомобиле. Карты туристических маршрутов, описание, необычные идеи для путешествия в Белгороде ${year}. Культурный регион`,
        titleOg: () => '',
      },
      overviews: {
        title: () => `Обзоры ${year} — Белгород. Культурный регион`,
        description: () => `Обзоры лучших событий, мест ${year} — Белгород. Культурный регион`,
        titleOg: () => '',
      },
      regionvideos: {
        title: () => `Видео о Белгородской области, ${year} — Культурный регион`,
        description: () => `Видео о Белгородской области, ${year} — Культурный регион`,
        titleOg: () => '',
      },
      hotels: {
        title: () => `Гостиницы в Белгороде ${year}. Культурный регион`,
        description: () => 'Гостиницы в Белгороде. Подробная информация: описания, адрес, режимы работы, фото, адреса на карте, рейтинги. Культурный регион',
        titleOg: () => '',
      },
      restaurants: {
        title: () => `Рестораны в Белгороде ${year}. Культурный регион`,
        description: () => 'Рестораны в Белгороде. Подробная информация: описания, режимы работы, фото, адреса на карте, рейтинги. Культурный регион',
        titleOg: () => '',
      },
      streams: {
        title: () => `Трансляции - Белгородская область ${year}. Культурный регион`,
        description: () => `Трансляции - Белгородская область ${year}. Прямые трансляции: смотреть онлайн и бесплатно. Официальный сайт Культурный регион`,
        titleOg: () => '',
        h1: () => `Трансляции - Белгородская область ${year}`,
      },
      ticketsBundles: {
        title: () => 'Абонементы. Культурный регион',
        description: () => 'Абонементы в Белгородской области. Культурный регион',
        titleOg: () => '',
      },
      movies: {
        title: () => `Киноафиша в Белгороде | Афиша мероприятий с точными датами на ${year} год - Культурный Регион`,
        description: () => `Киноафиша в Белгороде на ${year} год ✅ Афиша интересных мероприятий в Белгородской области. Подробная информация о событиях: ⭐ описания, места, расписания, ☎ телефоны. Культурный регион`,
        titleOg: () => '',
      },
      singleEvent: {
        title: ({ entity }) => `${entity.name} — описание, программа мероприятия, дата, время. Адрес места проведения — ${entity.address}. Афиша`,
        description: ({ entity }) => `${entity.name}. ✅ Место проведения — ${entity.placeName}, ✅ дата проведения — ${entity.eventDate ? moment(entity.eventDate).format('D MMMM YYYY') : ''}, ✅ адрес места проведения — ${entity.address}. Подробная информация: описание, фото. Официальный сайт — Культурный регион`,
        titleOg: ({ entity }) => `${entity.name}`,
      },
      singlePlace: {
        title: ({ entity }) => `${entity.name}. Адрес — ${entity.address}. Фото, режим работы, афиша и события. Культурный регион`,
        description: ({ entity }) => `${entity.name}. Адрес — ${entity.address} Подробная информация: описание, адрес на карте, время работы, телефон, фотографии. Все туристические места и достопримечательности на сайте — Культурный регион`,
        titleOg: ({ entity }) => `${entity.name}`,
      },
      singleRoute: {
        title: ({ entity }) => `${entity.name}. Подробная информация: описание, адреса мест на карте, фото. Культурный регион`,
        description: ({ entity }) => `${entity.name}. Лучшие туристические маршруты, которые можно исходить пешком или проехать на автомобиле в Белгородской области. Культурный регион`,
        titleOg: ({ entity }) => `${entity.name}`,
      },
      singleOverview: {
        title: ({ entity }) => `${entity.name}. Культурный регион`,
        description: ({ entity }) => `Обзор - ${entity.name}. Культурный регион`,
        titleOg: ({ entity }) => `${entity.name}`,
      },
      singleRegionVideo: {
        title: ({ entity }) => `Видео о Белгородской области, ${year}. ${entity.name}. Культурный регион`,
        description: ({ entity }) => `Видео о Белгородской области, ${year}. ${entity.name}. Культурный регион`,
        titleOg: ({ entity }) => `${entity.name}`,
      },
      singleHotel: {
        title: ({ entity }) => `Гостиница ${entity.name}. Адрес — ${entity.address}. Фото, режим работы. Культурный регион`,
        description: ({ entity }) => `Гостиница ${entity.name}. Адрес — ${entity.address} Подробная информация: описание, адрес на карте, время работы, телефон, фотографии. Все гостиницы на сайте — Культурный регион`,
        titleOg: ({ entity }) => `${entity.name}`,
      },
      singleRestaurant: {
        title: ({ entity }) => `Ресторан ${entity.name}. Адрес — ${entity.address}. Фото, режим работы. Культурный регион`,
        description: ({ entity }) => `Ресторан ${entity.name}. Адрес — ${entity.address} Подробная информация: описание, адрес на карте, время работы, телефон, фотографии. Все рестораны на сайте — Культурный регион`,
        titleOg: ({ entity }) => `${entity.name}`,
      },
      singleStream: {
        title: ({ entity }) => `Трансляция ${entity.name}. ${getStreamPlaceOrEvent(entity)}Прямая трансляция на сайте Культурный регион`,
        description: ({ entity }) => `${entity.name}. ${getStreamPlaceOrEvent(entity)}Дата и время проведения — ${entity.liveDate ? moment(entity.liveDate).format('D MMMM YYYY H:mm') : ''} на сайте Культурный регион`,
        titleOg: ({ entity }) => `${entity.name}`,
      },
      singleTicketsBundle: {
        title: ({ entity }) => `Абонемент ${entity?.name}. Белгород. Культурный регион`,
        description: ({ entity }) => `Абонемент ${entity?.name}. Купить билеты в Белгороде. Культурный регион`,
        titleOg: ({ entity }) => `${entity.name}`,
      },
      pushkincard: {
        title: () => ``,
        description: () => '',
        titleOg: () => ``,
      },
      main: { title: 'Культурный регион - Культурно-туристический портал Белгородской области' },
      museums: {
        title: () => `Виртуальные туры по музеям, ${year} — Белгород и Белгородская область | Культурный регион`,
        description: () => `Виртуальные туры по музеям Белгорода и Белгородской области,
        ${year} — посетите лучшие выставки, не выходя из дома. Культурный регион.`
      },
      singleMuseum: {
        title: ({ entity }) => ` Виртуальный тур по музею, ${year}. ${entity.name}. Белгород и Белгородская область. Культурный регион`,
        description: ({ entity }) => `Виртуальный тур по музею, ${year} — посетите выставку, не выходя из дома. Музей — ${entity.name}. Белгород и Белгородская область. Культурный регион`
      },
      halls: {
        title: () => `Виртуальные концертные залы, ${year} — Белгород и Белгородская область | Культурный регион`,
        description: () => `Виртуальные концертные залы, ${year}. Белгород и Белгородская область. Культурный регион.`
      },
      singleHall: {
        title: ({ entity }) => `Виртуальные концертные залы, ${year}, ${entity.name}. Белгород и Белгородская область. Культурный регион`,
        description: ({ entity }) => `Виртуальные концертные залы, ${year}, ${entity.name}. Белгород и Белгородская область. Культурный регион.`
      },
      artefacts: {
        title: () => `Дополненная реальность, ${year} — Белгород и Белгородская область | Культурный регион`,
        description: () => `Гиды дополненной реальности и интерактивные мероприятия,
        ${year} — Белгорода и Белгородской области. Культурный регион.`
      },
      singleArtefact: {
        title: ({ entity }) => `Гид дополненной реальности, ${year} — ${entity?.name}. Культурный регион`,
        description: ({ entity }) => `Гид дополненной реальности, ${year} — ${entity?.name}. Культурный регион`,
      },
      search: {
        title: ({ searchQuery }) => `Поиск событий, мест, маршрутов, обзоров,
        ресторанов, гостиниц, абонементов по запросу «${searchQuery}»`,
        description: ({ searchQuery }) => `События, места, маршруты, обзоры,
        рестораны, гостиницы, абонементы по запросу «${searchQuery}»`,
      },
      faq: {
        title: () => `Часто задаваемые вопросы. Официальный сайт — Культурный регион`,
        description: () => 'Часто задаваемые вопросы. Официальный сайт — Культурный регион',
      },
      // Порядок вложенности необходимо соблюдать, иначе функция получения сео метаданных отработает неправильно
      // В единсвенном числе - фильтр (locale, place, date, ...), с приставкой Page(eventsPage, hotelsPage, ...) - страница, для которой метаданные
      // Примеры:
      // locale->date->eventsPage: Фильтр район + фильтр дата для страницы событий
      // place->tag->eventsPage->default: Фильтр место + тэг для страницы событий
      // tag->eventsPage->pushkinskaya-karta: Тэг Пушкцинская карта для страницы событий
      filters: {
        locale: {
          eventsPage: {
            title: ({ localeName }) => `${localeName} - афиша ${year}. Интересные развлекательные события. Культурный регион`,
            description: ({ localeName }) => `${localeName} - афиша интересных мероприятий. Подробная информация о событиях: описания, места проведения, расписания, ☎ телефоны. Официальный сайт Культурный регион`,
            h1: ({ localeName }) => `${localeName} - афиша`,
          },
          placesPage: {
            title: ({ localeName }) => `Интересные места - ${localeName} ${year}. Культурный регион`,
            description: ({ localeName }) => `Все интересные места и достопримечательности - ${localeName} ${year} — описания, цены на билеты, режим работы, фотографии, адреса на карте. Официальный сайт Культурный регион`,
            h1: ({ localeName }) => `Места - ${localeName}`
          },
          restaurantsPage: {
            title: ({ localeName }) => `Кафе и рестораны - ${localeName} ${year}. Культурный регион`,
            description: ({ localeName }) => `Кафе и рестораны - ${localeName}. Подробная информация: описания, режимы работы, фото, адреса на карте, рейтинги. Официальный сайт Культурный регион`,
            h1: ({ localeName }) => `Кафе и рестораны - ${localeName}`
          },
          hotelsPage: {
            title: ({ localeName }) => `Гостиницы и отели - ${localeName} ${year}. Культурный регион`,
            description: ({ localeName }) => `Гостиницы и отели - ${localeName}. Подробная информация: описания, режимы работы, фото, адреса на карте, рейтинги, парковка, завтраки, спа и т. д. Официальный сайт Культурный регион`,
            h1: ({ localeName }) => `Гостиницы и отели - ${localeName}`
          },
          tag: {
            eventsPage: {
              default: {
                title: ({ localeName, tagName }) => `${capitalize(localeName)} - ${tagName}. Интересные развлекательные события. Культурный регион`,
                description: ({ localeName, tagName }) => `${capitalize(tagName)} - ${localeName}. Подробная информация о мероприятиях: расписания, описания, фото, места проведения, цены на билеты. Официальный сайт Культурный регион`,
                h1: ({ localeName, tagName }) => `${capitalize(localeName)} - ${tagName}`,
              }
            },
            placesPage: {
              default: {
                title: ({ localeName, tagName }) => `${capitalize(tagName)} - ${localeName} ${year}. Интересные места. Культурный регион`,
                description: ({ localeName, tagName }) => `${capitalize(tagName)} - ${localeName}. Подробная информация о месте: описания, фото, адрес на карте, время работы. Официальный сайт Культурный регион`,
                h1: ({ localeName, tagName }) => `${capitalize(tagName)} - ${localeName}`,
              }
            },
            hotelsPage: {
              default: {
                title: ({ localeName, tagName }) => `${capitalize(tagName)} - ${localeName} ${year}. Интересные места. Культурный регион`,
                description: ({ localeName, tagName }) => `${capitalize(tagName)} - ${localeName}. Подробная информация о месте: описания, фото, адрес на карте, время работы. Официальный сайт Культурный регион`,
                h1: ({ localeName, tagName }) => `${capitalize(tagName)} - ${localeName}`,
              }
            },
            restaurantsPage: {
              default: {
                title: ({ localeName, tagName }) => `${capitalize(tagName)} - ${localeName} ${year}. Интересные места. Культурный регион`,
                description: ({ localeName, tagName }) => `${capitalize(tagName)} - ${localeName}. Подробная информация о месте: описания, фото, адрес на карте, время работы. Официальный сайт Культурный регион`,
                h1: ({ localeName, tagName }) => `${capitalize(tagName)} - ${localeName}`,
              }
            }
          },
          place: {
            eventsPage: {
              title: ({ placeName }) => `${placeName} - афиша ${year}. Интересные развлекательные события. Культурный регион`,
              description: ({ placeName }) => `${placeName} - афиша интересных мероприятий. Подробная информация о событиях: описания, места проведения, расписания, ☎ телефоны. Официальный сайт Культурный регион`,
              h1: ({ placeName }) => `${placeName} - афиша`,
            }
          },
          date: {
            eventsPage: {
              title: ({ localeName, date }) => `${capitalize(localeName)} - афиша на ${date} ${year} года. Интересные развлекательные события. Культурный регион`,
              description: ({ localeName, date }) => `${capitalize(localeName)} - афиша на ${date} ${year} года. Интересные мероприятия в Белгородской области. Подробная информация о событиях: описания, места проведения, расписания, ☎ телефоны. Официальный сайт Культурный регион`,
              h1: ({ localeName, date }) => `${capitalize(localeName)} - афиша на ${date}`,
            }
          },
          interim: {
            eventsPage: {
              title: ({ localeName, interim, formattedDate, formattedYear }) => `${capitalize(localeName)} - афиша на ${interim}, ${formattedDate} ${formattedYear} года. Интересные развлекательные события. Культурный регион`,
              description: ({ localeName, interim, formattedDate, formattedYear }) => `${capitalize(localeName)} - афиша на ${interim}, ${formattedDate} ${formattedYear} года. Интересные мероприятия. Подробная информация о событиях: описания, места проведения, расписания, ☎ телефоны. Официальный сайт Культурный регион`,
              h1: ({ localeName, interim, formattedDate }) => `${capitalize(localeName)} - афиша на ${interim}, ${formattedDate}`,
            }
          }
        },
        place: {
          eventsPage: {
            title: ({ placeName }) => `${placeName} - афиша ${year}. Интересные развлекательные события. Культурный регион`,
            description: ({ placeName }) => `${placeName} - афиша интересных мероприятий. Подробная информация о событиях: описания, места проведения, расписания, ☎ телефоны. Официальный сайт Культурный регион`,
            h1: ({ placeName }) => `${placeName} - афиша`,
          },
          streamsPage: {
            title: ({ placeName }) => `Трансляции - ${placeName} ${year}. Культурный регион`,
            description: ({ placeName }) => `Трансляции - ${placeName} ${year}. Прямые трансляции: смотреть онлайн и бесплатно. Официальный сайт Культурный регион`,
            h1: ({ placeName }) => `Трансляции - ${placeName}`
          },
          date: {
            eventsPage: {
              title: ({ placeName, date }) => `${capitalize(placeName)} - афиша на ${date} ${year} года. Интересные развлекательные события. Культурный регион`,
              description: ({ placeName, date }) => `${capitalize(placeName)} - афиша на ${date} ${year} года. Интересные мероприятия в Белгородской области. Подробная информация о событиях: описания, места проведения, расписания, ☎ телефоны. Официальный сайт Культурный регион`,
              h1: ({ placeName, date }) => `${capitalize(placeName)} - афиша на ${date}`
            },
            streamsPage: {
              title: ({ placeName, date }) => `${capitalize(placeName)} - трансляции на ${date} ${year} года. Интересные развлекательные трансляции. Культурный регион`,
              description: ({ placeName, date }) => `${capitalize(placeName)} - трансляции на ${date} ${year} года. Интересные трансляции - Белгородская область. Подробная информация о трансляциях: описания, время проведения, расписания, ☎ телефоны. Официальный сайт Культурный регион`,
              h1: ({ placeName, date }) => `${capitalize(placeName)} - трансляции на ${date}`
            }
          },
          interim: {
            eventsPage: {
              title: ({ placeName, interim, formattedDate, formattedYear }) => `${capitalize(placeName)} - афиша на ${interim}, ${formattedDate} ${formattedYear} года. Интересные развлекательные события. Культурный регион`,
              description: ({ placeName, interim, formattedDate, formattedYear }) => `${capitalize(placeName)} - афиша на ${interim}, ${formattedDate} ${formattedYear} года. Интересные мероприятия. Подробная информация о событиях: описания, места проведения, расписания, ☎ телефоны. Официальный сайт Культурный регион`,
              h1: ({ placeName, interim, formattedDate }) => `${capitalize(placeName)} - афиша на ${interim}, ${formattedDate}`,
            },
            streamsPage: {
              title: ({ placeName, interim, formattedDate, formattedYear }) => `${capitalize(placeName)} - трансляции на ${interim}, ${formattedDate} ${formattedYear} года. Интересные развлекательные трансляции. Культурный регион`,
              description: ({ placeName, interim, formattedDate, formattedYear }) => `${capitalize(placeName)} - трансляции на ${interim}, ${formattedDate} ${formattedYear} года. Интересные трансляции - Белгородская область. Подробная информация о трансляциях: описания, время проведения, расписания, ☎ телефоны. Официальный сайт Культурный регион`,
              h1: ({ placeName, interim, formattedDate }) => `${capitalize(placeName)} - трансляции на ${interim}, ${formattedDate}`,
            }
          },
          tag: {
            eventsPage: {
              default: {
                title: ({ tagName, placeName }) => `${capitalize(placeName)} - ${tagName}. Интересные развлекательные события. Культурный регион`,
                description: ({ tagName, placeName }) => `${capitalize(placeName)} - ${tagName}. Подробная информация о мероприятиях: расписания, описания, фото, места проведения, цены на билеты. Официальный сайт Культурный регион`,
                h1: ({ tagName, placeName }) => `${capitalize(placeName)} - ${tagName}`,
              }
            }
          }
        },
        date: {
          eventsPage: {
            title: ({ formattedDate }) => `Афиша Белгорода на ${formattedDate} года. Интересные развлекательные события в Белгороде. Культурный регион`,
            description: ({ formattedDate }) => `Афиша в Белгороде на ${formattedDate} года. Интересные мероприятия Белгорода. Подробная информация о событиях: описания, места проведения, расписания, ☎ телефоны. Официальный сайт Культурный регион`,
            h1: ({ date }) => `Афиша на ${date}`,
          },
          moviesPage: {
            title: ({ formattedDate }) => `Киноафиша в Белгороде на ${formattedDate}. Кино в Белгороде - ${formattedDate}. Культурный регион`,
            description: ({ formattedDate }) => `Кино в Белгороде - ${formattedDate} ✅ Киноафиша в Белгороде на ${formattedDate}. Подробная информация о кино: ⭐ описания, цены, кинотеатры, расписания, ☎ телефоны. Официальный сайт Культурный регион`,
            h1: ({ date }) => `Киноафиша на ${date}`,
          },
          streamsPage: {
            title: ({ formattedDate }) => `Трансляции ${formattedDate} года. Культурный регион`,
            description: ({ formattedDate }) => `Трансляции - ${formattedDate} года. Прямые трансляции: смотреть онлайн и бесплатно. Официальный сайт Культурный регион`,
            h1: ({ date }) => `Трансляции - ${date}`,
          },
          tag: {
            eventsPage: {
              default: {
                title: ({ tagName, formattedDate }) => `${capitalize(tagName)} - афиша на ${formattedDate} года. Интересные развлекательные события. Культурный регион`,
                description: ({ tagName, formattedDate }) => `${capitalize(tagName)} - афиша на ${formattedDate} года. Интересные мероприятия - Белгородская область. Подробная информация о мероприятиях: описания, места проведения, расписания, ☎ телефоны. Официальный сайт Культурный регион`,
                h1: ({ tagName, date }) => `${capitalize(tagName)} - афиша на ${date}`,
              }
            },
          },
          genre: {
            moviesPage: {
              title: ({ genreName, formattedDate }) => `Киноафиша в жанре ${genreName} на ${formattedDate} года - Белгородская область. Культурный регион`,
              description: ({ genreName, formattedDate }) => `Кино в жанре ${genreName} на ${formattedDate} года - Белгородская область. Киноафиша на ${formattedDate} года - Белгородская область. Подробная информация о кино: описания, цены, кинотеатры, расписания, ☎ телефоны. Официальный сайт Культурный регион`,
              h1: ({ genreName, date }) => `Киноафиша в жанре ${genreName} на ${date}`,
            }
          }
        },
        interim: {
          eventsPage: {
            title: ({ interim, formattedDate }) => `Афиша на ${interim}, ${formattedDate} в Белгороде. Интересные развлекательные события в Белгороде. Культурный регион`,
            description: ({ interim, formattedDate }) => `Афиша Белгорода на ${interim}, ${formattedDate}. Интересные мероприятия Белгорода. Подробная информация о событиях: описания, места проведения, расписания, ☎ телефоны. Официальный сайт Культурный регион`,
            h1: ({ interim, formattedDate }) => `Афиша на ${interim}, ${formattedDate}`,
          },
          moviesPage: {
            title: ({ interim, formattedDate, formattedYear }) => `Киноафиша на ${interim}, ${formattedDate} ${formattedYear} года в Белгороде. Кино в Белгороде ${interim}, на ${formattedDate} ${formattedYear} года. Культурный регион`,
            description: ({ interim, formattedDate, formattedYear }) => `Кино в Белгороде на ${interim}, ${formattedDate} ${formattedYear} года ✅ Киноафиша в Белгороде ${interim}, ${formattedDate} ${formattedYear} года. Подробная информация о кино: ⭐ описания, цены, кинотеатры, расписания, ☎ телефоны. Официальный сайт Культурный регион`,
            h1: ({ interim, formattedDate }) => `Киноафиша на ${interim}, ${formattedDate}`,
          },
          streamsPage: {
            title: ({ interim, formattedDate, formattedYear }) => `Трансляции на ${interim}, ${formattedDate} ${formattedYear} года. Культурный регион`,
            description: ({ interim, formattedDate, formattedYear }) => `Трансляции на ${interim}, ${formattedDate} ${formattedYear} года. Прямые трансляции: смотреть онлайн и бесплатно. Официальный сайт Культурный регион`,
            h1: ({ interim, formattedDate }) => `Трансляции на ${interim}, ${formattedDate}`,
          },
          tag: {
            eventsPage: {
              default: {
                title: ({ tagName, interim, formattedDate }) => `${capitalize(tagName)} - афиша на ${interim}, ${formattedDate} ${year} года. Интересные развлекательные события. Культурный регион`,
                description: ({ tagName, interim, formattedDate }) => `${capitalize(tagName)} - афиша на ${interim}, ${formattedDate} ${year} года. Интересные мероприятия. Подробная информация о событиях: описания, места проведения, расписания, ☎ телефоны. Официальный сайт Культурный регион`,
                h1: ({ tagName, interim, formattedDate }) => `${capitalize(tagName)} - афиша на ${interim}, ${formattedDate}`,
              }
            },
          },
          genre: {
            moviesPage: {
              title: ({ genreName, interim, formattedDate }) => `Киноафиша в жанре ${genreName} на ${interim}, ${formattedDate} ${year} года - Белгородская область. Культурный регион`,
              description: ({ genreName, interim, formattedDate }) => `Кино в жанре ${genreName} на ${interim}, ${formattedDate} ${year} года - Белгородская область. Киноафиша на ${interim} ${formattedDate} ${year} года - Белгородская область. Подробная информация о кино: описания, цены, кинотеатры, расписания, ☎ телефоны. Официальный сайт Культурный регион`,
              h1: ({ genreName, interim, formattedDate }) => `Киноафиша в жанре ${genreName} на ${interim}, ${formattedDate}`,
            }
          }
        },
        tag: {
          eventsPage: {
            'pushkinskaya-karta': {
              title: () => `Пушкинская карта ${year} в Белгороде. Купить билеты по Пушкинской карте в Белгороде. Культурный регион`,
              description: () => `Афиша мероприятий по Пушкинской карте Белгорода. Купить билеты по Пушкинской карте в кино, на концерты, на спектакли и другие мероприятия. Официальный сайт Культурный регион`,
              h1: () => `Пушкинская карта`
            },
            'koncerty-i-shou': {
              title: () => `Концерты в Белгороде ${year}. Афиша концертов Белгорода. Культурный регион`,
              description: () => `Расписания концертов в Белгороде. Купить билеты на концерты в Белгороде. Подробная информация о событиях: расписания, описания, фото, места проведения, цены на билеты. Официальный сайт Культурный регион`,
              h1: () => `Концерты и шоу`
            },
            'spektakli': {
              title: () => `Спектакли в Белгороде ${year}. Купить билеты на спектакль в Белгороде. Культурный регион`,
              description: () => `Афиша спектаклей в Белгороде. Купить билеты на спектакль в Белгороде. Подробная информация о событиях: расписания, описания, фото, места проведения, цены на билеты. Официальный сайт Культурный регион`,
              h1: () => `Спектакли`
            },
            'detyam': {
              title: () => `Детская афиша в Белгороде ${year}. Куда пойти с ребенком в Белгороде. Культурный регион`,
              description: () => `Детские мероприятия в Белгороде. Куда пойти с ребенком в Белгороде. Подробная информация о событиях: расписания, описания, фото, места проведения, цены на билеты. Официальный сайт Культурный регион`,
              h1: () => `Детям`
            },
            default: tagPageDefaultSeo,
          },
          streamsPage: {
            default: {
              title: ({ tagName }) => `Трансляции - ${tagName}. Культурный регион`,
              description: ({ tagName }) => `Трансляции - ${tagName}. Прямые трансляции: смотреть онлайн и бесплатно Официальный сайт Культурный регион`,
              h1: ({ tagName }) => `Трансляции - ${tagName}`,
            }
          },
          restaurantsPage: {
            default: {
              title: ({ tagName }) => `${capitalize(tagName)} Белгорода ${year}. Культурный регион`,
              description: ({ tagName }) => `${capitalize(tagName)} в Белгороде. Подробная информация: описания, режимы работы, фото, адреса на карте, рейтинги. Официальный сайт Культурный регион`,
              h1: ({ tagName }) => tagName,
            }
          },
          hotelsPage: {
            default: {
              title: ({ tagName }) => `Гостиницы и отели - ${tagName} ${year}. Культурный регион`,
              description: ({ tagName }) => `Гостиницы и отели - ${tagName}. Подробная информация: описания, режимы работы, фото, адреса на карте, рейтинги, парковка, завтраки, спа и т. д. Официальный сайт Культурный регион`,
              h1: ({ tagName }) => `Гостиницы и отели - ${tagName}`,
            }
          }
        },
        genre: {
          moviesPage: {
            title: ({ genreName }) => `Киноафиша в Белгороде, жанр - ${genreName}. Кино в Белгороде, жанр - ${genreName}. Культурный регион`,
            description: ({ genreName }) => `Кино в Белгороде, жанр - ${genreName} ✅ Киноафиша в Белгороде, жанр - ${genreName}. Подробная информация о кино: ⭐ описания, цены, кинотеатры, расписания, ☎ телефоны. Официальный сайт Культурный регион`,
            h1: ({ genreName }) => `Киноафиша, жанр - ${genreName}`
          },
        }
      },
    },
  },
};
