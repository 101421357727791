import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { api, paramsToQuery, paramsSerializer } from '../../urls';
import { IEvent } from "../types/event";
import { IPlace } from "../types/places";
import { IMovie } from "../types/movie";
import { IArticle } from "../types/articles";
import { ITicketsBundles } from "../types/ticketsBundles";
import { ITouristRoute } from "../types/picks";
import { IStream } from "../types/invoices";

interface IState {
  events: IEvent[];
  overviews: IArticle[];
  regionvideos: IArticle[];
  routes: IArticle[];


  forPlacesEvents: IPlace[];
  forPlacesStreams: IStream[];
  forPlacesPlaces: IPlace[];
  forPlacesVirtualMuseums: IPlace[];
  forPlacesVirtualHalls: IPlace[];
  forRestaurantsPlacesNear: IPlace[];
  forRestaurantsRestaurants: IPlace[];
  forHotelsPlacesNear: IPlace[];
  forHotelsRestaurantsNear: IPlace[];
  forHotelsHotels: IPlace[];
  forTicketsBundles: ITicketsBundles[];
  forEventsPlaces: IPlace[];
  forEventsOverviews: IArticle[];
  forTouristRoutesRoutes: ITouristRoute[];
  forPushkinPlaces: IPlace[];
  forMoviesMovies: IMovie[];
  listEvents: IEvent[];
  listOverviews: IArticle[];
  listPlaces: IPlace[];
  listTouristRoutes: ITouristRoute[];
  forStreamsStreams: IStream[];
  forArticlesArtefacts: IArticle[];
}

const recommendationFieldsList = ['_id', 'name', 'category', 'image', 'sysName', 'ticketsInfo'];
const recommendationFields = recommendationFieldsList.join(',');

export const loadRecommendationsForEvent = createAsyncThunk<any, any>(
  'recommendation/loadRecommendationsForEvent',
  async (params: any) => {
    const response = await axios.get(api.recommendations.forEvent.get(params._id), {
      params: { }
    });
    return {
      events: response.data.events,
    };
  }
);

export const loadRecommendationsForOverview =
  createAsyncThunk<any, any>('recommendation/loadRecommendationsForOverview',
    async (params: any) => {
      const response = await axios.get(api.recommendations.forOverviews.overviews.get(params._id), {
        params: { fields: recommendationFields }
      });
      return {
        overviews: response.data.overviews,
      };
    }
  );

export const loadRecommendationsForRoutes =
  createAsyncThunk<any, any>('recommendation/loadRecommendationsForRoute',
    async (params: any) => {
      const response = await axios.get(api.recommendations.forOverviews.routes.get(params._id), {
        params: { fields: recommendationFields }
      });
      return {
        routes: response.data.routes,
      };
    }
  );

export const loadRecommendationsForPlacesEvents =
  createAsyncThunk<any, any>('recommendation/loadRecommendationsForPlacesEvents',
    async (params: any) => {
      const response = await axios.get(api.recommendations.forPlaces.events.get(params._id), {
        params: { }
      });
      return {
        forPlacesEvents: response.data.events,
      };
    }
  );

export const loadRecommendationsForPlacesStreams =
  createAsyncThunk<any, any>('recommendation/loadRecommendationsForPlacesStreams',
    async (params: any) => {
      const response = await axios.get(api.recommendations.forPlaces.streams.get(params._id), {
        params: { }
      });
      return {
        forPlacesStreams: response.data.streams,
      };
    }
  );

export const loadRecommendationsForPlacesPlaces =
  createAsyncThunk<any, any>('recommendation/loadRecommendationsForPlacesPlaces',
    async (params: any) => {
      const response = await axios.get(api.recommendations.forPlaces.places.get(params._id), {
        params: { fields: recommendationFields }
      });
      return {
        forPlacesPlaces: response.data.places,
      };
    }
  );

export const loadRecommendationsForPlacesVirtualMuseums =
  createAsyncThunk<any, any>('recommendation/loadRecommendationsForPlacesVirtualMuseums',
    async (params: any) => {
      const response = await axios.get(api.recommendations.forPlaces.virtualMuseums.get(params._id), {
        params: { fields: recommendationFields }
      });
      return {
        forPlacesVirtualMuseums: response.data.places,
      };
    }
  );

export const loadRecommendationsForPlacesVirtualHalls = createAsyncThunk<any, any>(
  'recommendation/loadRecommendationsForPlacesVirtualHalls',
  async (params: any) => {
    const response = await axios.get(api.recommendations.forPlaces.virtualHalls.get(params._id), {
      params: { fields: recommendationFields }
    });
    return {
      forPlacesVirtualHalls: response.data.places,
    };
  }
);

export const loadRecommendationsForRestaurantsPlacesNear =
  createAsyncThunk<any, any>('recommendation/loadRecommendationsForRestaurantsPlacesNear',
    async (params: any) => {
      const response = await axios.get(api.recommendations.forRestaurants.placesNear.get(params._id), {
        params: { fields: recommendationFields }
      });
      return {
        forRestaurantsPlacesNear: response.data.places,
      };
    }
  );


export const loadRecommendationsForRestaurantsRestaurants =
  createAsyncThunk<any, any>('recommendation/loadRecommendationsForRestaurantsRestaurants',
    async (params: any) => {
      const response = await axios.get(api.recommendations.forRestaurants.restaurants.get(params._id), {
        params: { fields: recommendationFields }
      });
      return {
        forRestaurantsRestaurants: response.data.restaurants,
      };
    }
  );

export const loadRecommendationsForHotelsPlacesNear =
  createAsyncThunk<any, any>('recommendation/loadRecommendationsForHotelsPlacesNear',
    async (params: any) => {
      const response = await axios.get(api.recommendations.forHotels.placesNear.get(params._id), {
        params: { fields: recommendationFields }
      });
      return {
        forHotelsPlacesNear: response.data.places,
      };
    }
  );

export const loadRecommendationsForHotelsRestaurantsNear =
  createAsyncThunk<any, any>('recommendation/loadRecommendationsForHotelsRestaurantsNear',
    async (params: any) => {
      const response = await axios.get(api.recommendations.forHotels.restaurantsNear.get(params._id), {
        params: { fields: recommendationFields }
      });
      return {
        forHotelsRestaurantsNear: response.data.restaurants,
      };
    }
  );

export const loadRecommendationsForHotelsHotels =
  createAsyncThunk<any, any>('recommendation/loadRecommendationsForHotelsHotels',
    async (params: any) => {
      const response = await axios.get(api.recommendations.forHotels.hotels.get(params._id), {
        params: { fields: recommendationFields }
      });
      return {
        forHotelsHotels: response.data.hotels,
      };
    }
  );

export const loadRecommendationsForEventsPlaces =
  createAsyncThunk<any, any>('recommendation/loadRecommendationsForEventsPlaces',
    async (params: any, { rejectWithValue }) => {
      try {
        const response = await axios.get(api.recommendations.forEvents.places.get(), {
          params: { fields: recommendationFields }
        });
        return {
          forEventsPlaces: response.data.places,
        };
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );

export const loadRecommendationsForEventsOverviews =
  createAsyncThunk<any, any>('recommendation/loadRecommendationsForEventsOverviews',
    async () => {
      const response = await axios.get(api.recommendations.forEvents.overviews.get(), {
        params: { fields: recommendationFields }
      });
      return {
        forEventsOverviews: response.data.articles,
      };
    }
  );

export const loadRecommendationsEvents =
  createAsyncThunk<{ listEvents: IEvent[] }, { locales: string | string[] }>('recommendation/loadRecommendationsEvents',
    async (params) => {
      const response = await axios.get(
        api.recommendations.events.get(),
        { params: { ...paramsToQuery(params) }, paramsSerializer }
      );
      return {
        listEvents: response.data.events,
      };
    }
  );

export const loadRecommendationsRoutes =
  createAsyncThunk<any, any>('recommendation/loadRecommendationsRoutes',
    async () => {
      const response = await axios.get(api.recommendations.touristRoutes.get(), {
        params: { fields: recommendationFields }
      });
      return {
        listTouristRoutes: response.data.touristRoutes,
      };
    }
  );

export const loadRecommendationsOverviews =
  createAsyncThunk<any, any>('recommendation/loadRecommendationsOverviews',
    async () => {
      const response = await axios.get(api.recommendations.overviews.get(), {
        params: { fields: recommendationFields }
      });
      return {
        listOverviews: response.data.articles,
      };
    }
  );

export const loadRecommendationsForArticlesArtefacts =
  createAsyncThunk<any, any>('recommendation/loadRecommendationsForArticlesArtefacts',
    async (params: any) => {
      const response = await axios.get(api.recommendations.forArticle.artefacts.get(params._id), {
        params: { fields: recommendationFields }
      });
      return {
        forArticlesArtefacts: response.data.articles,
      };
    }
  );

export const loadRecommendationsRegionVideos =
  createAsyncThunk<any, any>('recommendation/loadRecommendationsRegionVideos',
    async (params: any) => {
      const response = await axios.get(api.recommendations.forArticle.get(params._id), {
        params: { fields: recommendationFields }
      });
      return {
        regionvideos: response.data.articles,
      };
    }
  );

export const loadRecommendationsPlaces =
  createAsyncThunk<{ listPlaces: IPlace[] }, { locales: string | string[] }>('recommendation/loadRecommendationsPlaces',
    async (params) => {
      const response = await axios.get(
        api.recommendations.places.get(),
        { params: { ...paramsToQuery(params), fields: recommendationFields }, paramsSerializer }
      );
      return {
        listPlaces: response.data.places,
      };
    }
  );

export const loadRecommendationsForPushkinPlaces =
  createAsyncThunk<any, any>('recommendation/loadRecommendationsForPushkinPlaces',
    async () => {
      const response = await axios.get(api.recommendations.pushkinPlaces.get(), {
        params: { fields: recommendationFields }
      });
      return {
        forPushkinPlaces: response.data.places,
      };
    }
  );

export const loadRecommendationsMoviesForMovies =
  createAsyncThunk<any, any>('recommendation/loadRecommendationsMoviesForMovies',
    async (params: any) => {
      const response = await axios.get(api.recommendations.forMovies.movies.get(params._id), {
        params: { fields: recommendationFields }
      });
      return {
        forMoviesMovies: response.data.movies,
      };
    }
  );

export const loadRecommendationsForTicketsBundles =
  createAsyncThunk<any, any>('recommendation/loadRecommendationsForTicketsBundles',
    async (params: { id: number }) => {
      const response = await axios.get(api.recommendations.forTicketsBundles.get(params.id), {
        params: { fields: recommendationFields }
      });
      return {
        forTicketsBundles: response.data.ticketsBundles,
      };
    }
  );

export const loadRecommendationsForTouristRoutes =
  createAsyncThunk<
  { forTouristRoutesRoutes: ITouristRoute }, { id: number }
  >('recommendation/loadRecommendationsForTouristRoutes',
    async (params: { id: number }) => {
      const response = await axios.get(api.recommendations.forTouristRoutes.get(params.id), {
        params: { fields: recommendationFields }
      });
      return {
        forTouristRoutesRoutes: response.data.touristRoutes,
      };
    }
  );

export const loadRecommendationsForStreams =
  createAsyncThunk<any, any>('recommendation/loadRecommendationsForStreams',
    async (params: { id: number }) => {
      const response = await axios.get(api.recommendations.forStreamsStreams.get(params.id) + '?limit=4');
      return {
        forStreamsStreams: response.data.streams,
      };
    }
  );

export const recommendationSlice = createSlice<IState, any>({
  name: 'recommendation',
  initialState: {
    events: [],
    overviews: [],
    regionvideos: [],
    routes: [],
    forPlacesEvents: [],
    forPlacesStreams: [],
    forPlacesPlaces: [],
    forPlacesVirtualMuseums: [],
    forPlacesVirtualHalls: [],
    forRestaurantsPlacesNear: [],
    forTicketsBundles: [],
    forRestaurantsRestaurants: [],
    forHotelsPlacesNear: [],
    forHotelsRestaurantsNear: [],
    forHotelsHotels: [],
    forEventsPlaces: [],
    forEventsOverviews: [],
    forTouristRoutesRoutes: [],
    forPushkinPlaces: [],
    forMoviesMovies: [],
    forStreamsStreams: [],
    listEvents: [],
    listTouristRoutes: [],
    listOverviews: [],
    listPlaces: [],
    forArticlesArtefacts: [],
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadRecommendationsForEvent.fulfilled, (state, { payload }) =>
      Object.assign(state, payload));
    builder.addCase(loadRecommendationsRegionVideos.fulfilled, (state, { payload }) =>
      Object.assign(state, payload));
    builder.addCase(loadRecommendationsForPlacesEvents.fulfilled, (state, { payload }) =>
      Object.assign(state, payload));
    builder.addCase(loadRecommendationsForPlacesStreams.fulfilled, (state, { payload }) =>
      Object.assign(state, payload));
    builder.addCase(loadRecommendationsForPlacesPlaces.fulfilled, (state, { payload }) =>
      Object.assign(state, payload));
    builder.addCase(loadRecommendationsForPlacesVirtualMuseums.fulfilled, (state, { payload }) =>
      Object.assign(state, payload));
    builder.addCase(
      loadRecommendationsForPlacesVirtualHalls.fulfilled, (state, { payload }) =>
        Object.assign(state, payload));
    builder.addCase(loadRecommendationsForOverview.fulfilled, (state, { payload }) =>
      Object.assign(state, payload));
    builder.addCase(loadRecommendationsForRoutes.fulfilled, (state, { payload }) =>
      Object.assign(state, payload));
    builder.addCase(loadRecommendationsForRestaurantsPlacesNear.fulfilled, (state, { payload }) =>
      Object.assign(state, payload));
    builder.addCase(loadRecommendationsForRestaurantsRestaurants.fulfilled, (state, { payload }) =>
      Object.assign(state, payload));
    builder.addCase(loadRecommendationsForEventsPlaces.fulfilled, (state, { payload }) =>
      Object.assign(state, payload));
    builder.addCase(loadRecommendationsForEventsOverviews.fulfilled, (state, { payload }) =>
      Object.assign(state, payload));
    builder.addCase(loadRecommendationsEvents.fulfilled, (state, { payload }) =>
      Object.assign(state, payload));
    builder.addCase(loadRecommendationsRoutes.fulfilled, (state, { payload }) =>
      Object.assign(state, payload));
    builder.addCase(loadRecommendationsOverviews.fulfilled, (state, { payload }) =>
      Object.assign(state, payload));
    builder.addCase(loadRecommendationsForArticlesArtefacts.fulfilled, (state, { payload }) =>
      Object.assign(state, payload));
    builder.addCase(loadRecommendationsForHotelsHotels.fulfilled, (state, { payload }) =>
      Object.assign(state, payload));
    builder.addCase(loadRecommendationsForHotelsRestaurantsNear.fulfilled, (state, { payload }) =>
      Object.assign(state, payload));
    builder.addCase(loadRecommendationsForHotelsPlacesNear.fulfilled, (state, { payload }) =>
      Object.assign(state, payload));
    builder.addCase(loadRecommendationsPlaces.fulfilled, (state, { payload }) =>
      Object.assign(state, payload));
    builder.addCase(loadRecommendationsMoviesForMovies.fulfilled, (state, { payload }) =>
      Object.assign(state, payload));
    builder.addCase(loadRecommendationsForPushkinPlaces.fulfilled, (state, { payload }) =>
      Object.assign(state, payload));
    builder.addCase(loadRecommendationsForTicketsBundles.fulfilled, (state, { payload }) =>
      Object.assign(state, payload));
    builder.addCase(loadRecommendationsForTouristRoutes.fulfilled, (state, { payload }) =>
      Object.assign(state, payload));
    builder.addCase(loadRecommendationsForStreams.fulfilled, (state, { payload }) =>
      Object.assign(state, payload));

  }
});

export const { setActiveMobileTab } = recommendationSlice.actions;
