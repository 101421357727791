import { priceToString } from "./money";

export const isTicketsInfoAvailable = ({ unlimited = false, total = 0, available = 0 } = {}, withSoldout?: boolean) =>
  Boolean(unlimited || available || withSoldout && total);

export const areTicketsSoldOut = ({ total = 0, available = 0 } = {}) =>
  available === 0 && total > available;

export const getTicketsInfoPriceStr = ({ maxPrice, minPrice }, omitRouble = false) => {
  if (minPrice === maxPrice) {
    return priceToString(minPrice, { omitRouble });
  }

  return `от ${priceToString(minPrice, { omitRouble })}`;
};
